<template>
  <b-card header="Ultimissime...">
    <p>
      <a
        href="https://twitter.com/BuonaCaccia"
        class="twitter-follow-button"
        data-show-count="true"
        data-lang="it"
        data-size="medium"
        data-width="270px"
        >Segui @@BuonaCaccia</a
      >
    </p>
    <iframe
      src="//www.facebook.com/plugins/like.php?href=http%3A%2F%2Fwww.facebook.com%2FBuonaCaccia&amp;send=false&amp;layout=button_count&amp;width=270&amp;show_faces=false&amp;action=recommend&amp;colorscheme=light&amp;font&amp;height=21"
      scrolling="no"
      frameborder="0"
      style="border: none; overflow: hidden; width: 270px; height: 21px"
      allowTransparency="true"
    ></iframe>
    <ul class="twitter">
      <li v-for="tweet in tweetList" :key="tweet.id">
        <span v-html="tweet.text"></span><br />
        <em class="tiny">{{ tweet.when }}</em>
      </li>
    </ul>
    <p>
      <a
        href="https://twitter.com/BuonaCaccia"
        class="twitter-follow-button"
        data-show-count="true"
        data-lang="it"
        data-size="large"
        data-width="270px"
        >Segui @@BuonaCaccia</a
      >
    </p>
  </b-card>
</template>

<script>
function relativize(timestamp) {
  const ts = Date.parse(timestamp);
  const span = Date.now() - ts;

  if (span < 1 * 1000) return "or ora";
  if (span < 15 * 60 * 1000) return "pochi minuti fa";
  if (span < 45 * 60 * 1000) return "mezz'ora fa";
  if (span < 90 * 60 * 1000) return "un'ora fa";
  if (span < 24 * 60 * 60 * 1000)
    return Math.trunc(span / (60 * 60 * 1000)) + " ore fa";
  if (span < 48 * 60 * 60 * 1000) return "un giorno fa";
  if (span < 16 * 24 * 60 * 60 * 1000)
    return Math.trunc(span / (24 * 60 * 60 * 1000)) + " giorni fa";

  return Date(ts).ToDateString();
}

export default {
  name: "NewsFeed",
  props: {
    tweets: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      tweetList: [],
    };
  },
  mounted() {
    /* eslint-disable */
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0],
        t = window.twttr || {};
      if (d.getElementById(id)) return t.widgets.load();
      js = d.createElement(s);
      js.id = id;
      js.src = "https://platform.twitter.com/widgets.js";
      fjs.parentNode.insertBefore(js, fjs);

      t._e = [];
      t.ready = function (f) {
        t._e.push(f);
      };
      return t;
    }(document, "script", "twitter-wjs"));
    /* eslint-enable */

    this.$http
      .get("/v0.1/uiassist/tweets")
      .then((response) => {
        response.data.forEach((t) => {
          t.text = t.text.replace(/[a-z]+:\/\/[^\s]+/, function (url) {
            return "<a href='" + url + "'>link</a>";
          });
          t.when = relativize(t.created);
        });

        this.tweetList = response.data.slice(0, this.tweets);
        // Save retrieval time
      })
      .catch(() => {
        this.tweetList = [
          { text: "Newsfeed non disponibile: riprova pi&ugrave; tardi..." },
        ];
      });
  },
};
</script>
