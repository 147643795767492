import authenticationService from "../services/AuthenticationService.js";

export default {
  data() {
    return {
      user: {
        name: "",
        roles: [],
        isAuthenticated: false,
      },
    };
  },

  computed: {
    isAdmin() {
      return this.user.roles.includes("Admin");
    },
    isFocusGroup() {
      return this.user.roles.includes("FocusGroup");
    },
    canRead(region) {
      return this.user.roles.includes("Data_" + region);
    },
    canWrite(region) {
      return this.user.roles.includes("Data_" + region);
    },
  },

  methods: {
    async refreshUserInfo() {
      const user = await authenticationService.getUser();
      if (user) {
        this.user.name = user.profile.name;
        this.user.roles = user.profile.role;
        this.user.isAuthenticated = true;
      } else {
        this.user.name = "";
        this.user.roles = [];
        this.user.isAuthenticated = false;
      }
    },
  },

  async created() {
    await this.refreshUserInfo();
  },
};
