<template>
  <header>
    <b-navbar
      toggleable="md"
      fixed="top"
      type="dark"
      variant="admin"
      class="flex-md-nowrap p-2 shadow"
    >
      <b-container fluid>
        <b-navbar-brand class="col-sm-3 col-md-2 mr-0" href="#">
          <img src="@/images/BCnavbar.png" height="30px" />
        </b-navbar-brand>

        <b-input-group size="sm">
          <b-input-group-prepend>
            <span class="input-group-text">
              <fa-icon :icon="['far', 'search']" size="lg" fixed-width />
            </span>
          </b-input-group-prepend>
          <b-form-input
            class="form-control-dark"
            placeholder="Ricerca"
            v-model="searchText"
            @keydown.enter.native="search"
          ></b-form-input>
        </b-input-group>

        <b-navbar-nav class="pl-3">
          <b-nav-item to="/admin/supervisione">
            <fa-icon :icon="['far', 'fire']" size="lg" fixed-width />
          </b-nav-item>

          <b-nav-item-dropdown text="M" class="px-1 text-nowrap">
            <b-dropdown-item href="#">...</b-dropdown-item>
            <b-dropdown-item href="#">...</b-dropdown-item>
            <b-dropdown-item href="#">...</b-dropdown-item>
            <b-dropdown-item href="#">...</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown text="[Anno]" class="px-1 text-nowrap">
            <b-dropdown-item href="#">...</b-dropdown-item>
            <b-dropdown-item href="#">...</b-dropdown-item>
            <b-dropdown-item href="#">...</b-dropdown-item>
            <b-dropdown-item href="#">...</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown text="[Regione]" class="px-1 text-nowrap">
            <b-dropdown-item href="#">...</b-dropdown-item>
            <b-dropdown-item href="#">...</b-dropdown-item>
            <b-dropdown-item href="#">...</b-dropdown-item>
            <b-dropdown-item href="#">...</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item href="#" @click="login" v-if="!user.isAuthenticated">
            Accedi
          </b-nav-item>
          <b-nav-item-dropdown
            v-else
            :text="user.name"
            class="px-1 text-nowrap"
          >
            <b-dropdown-item :to="{ name: 'adminHome' }">
              Area Utente
            </b-dropdown-item>
            <b-dropdown-item href="#">Profilo Utente</b-dropdown-item>
            <b-dropdown-item href="#">Cambio Password</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item href="#" @click="logout()">Esci</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-container>
    </b-navbar>
  </header>
</template>

<script>
import UserAuth from "../../mixins/userAuth.js";
import AuthenticationService from "../../services/AuthenticationService.js";
import Toaster from "../../mixins/toaster.js";

const actions = {
  events: "events",
  persons: "persone",
  suscriptions: "iscrizioni",
};

export default {
  name: "NavbarPublic",
  mixins: [UserAuth, Toaster],
  data() {
    return {
      searchText: "",
    };
  },
  computed: {
    username() {
      return this.$store.state.userName;
    },
  },
  methods: {
    login() {
      AuthenticationService.login();
    },
    logout() {
      AuthenticationService.logout();
    },
    search() {
      let normalizedText = this.searchText.toLowerCase().replace(" ", "");
      let targetAction;
      let targetPointer;

      if (normalizedText.startsWith("evento")) {
        targetAction = actions.events;
        targetPointer = 6;
      } else if (normalizedText.startsWith("eid")) {
        targetAction = actions.events;
        targetPointer = 3;
      } else if (normalizedText.startsWith("evt")) {
        targetAction = actions.events;
        targetPointer = 3;
      } else if (normalizedText.startsWith("e")) {
        targetAction = actions.events;
        targetPointer = 1;
      } else if (normalizedText.startsWith("persona")) {
        targetAction = actions.persons;
        targetPointer = 7;
      } else if (normalizedText.startsWith("pid")) {
        targetAction = actions.persons;
        targetPointer = 3;
      } else if (normalizedText.startsWith("p")) {
        targetAction = actions.persons;
        targetPointer = 1;
      } else if (normalizedText.startsWith("iscrizione")) {
        targetAction = actions.suscriptions;
        targetPointer = 10;
      } else if (normalizedText.startsWith("bc")) {
        targetAction = actions.suscriptions;
        targetPointer = 2;
      } else if (normalizedText.startsWith("i")) {
        targetAction = actions.suscriptions;
        targetPointer = 1;
      } else {
        this.toast("danger", "Ricerca", "Richiesta non riconosciuta");
        return;
      }

      if (isNaN(normalizedText.substring(targetPointer))) {
        this.toast("danger", "Ricerca", "Elemento non riconosciuto");
        return;
      }

      this.searchText = "";
      this.$router.push(
        "/admin/" + targetAction + "/" + normalizedText.substring(targetPointer)
      );
    },
  },
};
</script>
