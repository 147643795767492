export default {
  data() {
    return {};
  },

  computed: {},

  methods: {
    toast(variant, title, text) {
      this.$bvToast.toast(text, {
        //toaster: "b-toaster-bottom-right",
        variant: variant,
        title: title,
        autoHideDelay: 7000,
        solid: true,
      });
    },
  },
};
