<template>
  <div>
    <bc-navbar-admin />

    <b-container fluid>
      <div class="row">
        <div class="col-md-2 d-none d-md-block bg-light sidebar">
          <bc-sidebar-admin />
        </div>
        <div class="col-md-9 ml-sm-auto col-lg-10 px-4">
          <div class="content-spacer-sm">
            <router-view />
            <bc-footer-admin />
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import bcNavbarAdmin from "./components/NavbarAdmin.vue";
import bcSidebarAdmin from "./components/SidebarAdmin.vue";
import bcFooterAdmin from "./components/FooterAdmin.vue";

export default {
  name: "LayoutAdmin",
  components: {
    "bc-navbar-admin": bcNavbarAdmin,
    "bc-sidebar-admin": bcSidebarAdmin,
    "bc-footer-admin": bcFooterAdmin,
  },
};
</script>
