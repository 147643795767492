<template>
  <div>
    <h1>Benvenenuto su BuonaCaccia admin!</h1>

    <b-row>
      <b-col>
        <b-card> Admin dashboard... </b-card>
      </b-col>

      <b-col cols="4">
        <bc-newsfeed tweets="5" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import bcNewsfeed from "../../shared/NewsFeed.vue";

export default {
  name: "Home",
  components: {
    "bc-newsfeed": bcNewsfeed,
  },
};
</script>
